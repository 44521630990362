import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DropdownCalendar, DropdownFilter, Input, Modal } from '@cognitiv/cyprus-ui';
import { clearFlight } from 'ducks/actions/flights';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { transitionFlights } from 'ducks/normalizers/flights';
import { createCampaignFlight, updateFlight } from 'ducks/operators/flights';
import { getAdvertiserSalesforceFlights } from 'ducks/operators/salesforce-flights';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';

const default_state = {
  start_date_formatted: null,
  finish_date_formatted: null,
  state_id: 1,
  opportunity_id: null,
  is_salesforce_sync_enabled: false,
  opportunity_name: '',
};

export const ManageFlight = ({ history, location, match }) => {
  const dispatch = useDispatch();

  const { flight, modals, salesforce_flights } = useSelector(
    (state) => ({
      flight: state.flight,
      modals: state.modals,
      salesforce_flights: state.salesforce_flights,
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state, ...flight });
  }, [flight]);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    // checking params to validate on flight page
    if (!match.params.flight_id) {
      dispatch(clearFlight());
      dispatch(getAdvertiserSalesforceFlights({ filter: '' }));
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_flight: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { search, pathname } = location;
    const { flight_id } = flight;
    const { advertiser_id, campaign_id } = match.params;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        flight_name: form.flight_name,
        campaign_id: form.campaign_id,
        start_date_utc: form.start_date_formatted,
        finish_date_utc: form.finish_date_formatted,
        is_salesforce_sync_enabled: form.is_salesforce_sync_enabled || false,
        state_id: form.state_id,
        opportunity_id: form.opportunity_id,
      };

      // accounting for utc offsets
      const flight_dates = transitionFlights(data.start_date_utc, data.finish_date_utc);

      data.start_date_utc = flight_dates.start_date_utc;
      data.finish_date_utc = flight_dates.finish_date_utc;

      if (flight_id) {
        await dispatch(updateFlight(flight_id, data));
        history.push(`${pathname}${queries}`);
      }
      if (!flight_id) {
        const entity_id = await dispatch(createCampaignFlight(campaign_id, data));
        history.push(
          `/advertisers/${advertiser_id}/campaigns/${campaign_id}/flights/${entity_id}/line-items?highlights=true`,
        );
      }

      dispatch(updateSuccess('flight has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const onChangeSalesforceOpportunity = async ({ value }) => {
    setForm((prev) => ({
      ...prev,
      opportunity_name: value,
    }));

    dispatch(
      getAdvertiserSalesforceFlights({
        filter: String(value || ''),
      }),
    );
    dispatch(updateSettings({ loading: false }));
  };

  const { flight_id } = flight;
  const { flight_name, start_date_formatted, finish_date_formatted, opportunity_name, is_salesforce_sync_enabled, opportunity_id } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_flight"
      show={modals.manage_flight}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>{flight_id ? 'Update Flight' : 'Create Flight'}</h3>
      <form onSubmit={onSubmit}>
        <DropdownFilter
          label="Salesforce Opportunity"
          autofocus
          options={salesforce_flights}
          value={opportunity_name}
          option_key="opportunity_name"
          onSelect={(item) =>
            onChange({
              opportunity_id: item.opportunity_id,
              opportunity_name: item.opportunity_name,
              is_salesforce_sync_enabled: true,
            })
          }
          onChange={(value) => onChangeSalesforceOpportunity({ value })}
          margin="0px 0px 15px 0px"
        />
        {flight_id && (
          <Input
            label="Flight Name"
            value={flight_name}
            margin="0px 0px 15px 0px"
            onChange={(value) => onChange({ flight_name: value })}
          />
        )}
        <div style={{ display: 'flex' }}>
          <DropdownCalendar
            label="Start Date"
            selected_date={start_date_formatted}
            max_date={finish_date_formatted}
            onSelect={(date) => onChange({ start_date_formatted: date.selected_date })}
            margin="0px 7px 15px 0px"
            width="calc(50% - 7px)"
            calendar_display={false}
          />
          <DropdownCalendar
            label="Finish Date"
            selected_date={finish_date_formatted}
            min_date={start_date_formatted}
            onSelect={(date) => onChange({ finish_date_formatted: date.selected_date })}
            margin="0px 0px 15px 7px"
            width="calc(50% - 7px)"
            calendar_display={false}
          />
        </div>
        {flight_id && <Checkbox
          onClick={(checked) => onChange({ is_salesforce_sync_enabled: checked })}
          disabled={!opportunity_id}
          checked={is_salesforce_sync_enabled}
          margin="10px 0"
          width="max-content"
        >
          Sync to Salesforce
        </Checkbox>}
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit">
            {flight_id ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageFlight.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};
