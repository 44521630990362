import { setSalesforceFlights } from 'ducks/actions/salesforce-flights';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeSalesforceFlights } from 'ducks/normalizers/salesforce-flights';
import { Titan } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

export const getAdvertiserSalesforceFlights = (queries) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    filter: String(queries.filter || ''),
    include_mapped_opportunities: true,
    ...queries,
  };

  const res = await Titan.get(`/salesforce/flights${replaceQuery(query)}`);
  const salesforce_flights = normalizeSalesforceFlights(res.data);
  await dispatch(setSalesforceFlights(salesforce_flights));
  return salesforce_flights;
};
